import {
  createClient as createURQLClient,
  mapExchange,
  subscriptionExchange,
} from '@urql/vue';
import { createClient as createWSClient } from 'graphql-ws';

import { cacheExchange } from '@urql/exchange-graphcache';
import { fetchExchange } from '@urql/core';

import * as Sentry from '@sentry/vue';

import Cookies from 'js-cookie';
import { parse } from 'date-fns';
import { Kind } from 'graphql';
import schema from './schema.json';
import { resolveISO } from './utils/date.js';

// TODO: rethink how we use these fragments
import REGISTRATIONS_QUERY from './queries/fragments/EventRegistrationsInformation.graphql';
import COMBINATIONS_QUERY from './queries/fragments/CombinationInformation.graphql';
import TEAMS_QUERY from './queries/fragments/TeamInformation.graphql';

const getOperationName = (query) => {
  for (let i = 0, l = query.definitions.length; i < l; i += 1) {
    const node = query.definitions[i];
    if (node.kind === Kind.OPERATION_DEFINITION && node.name) {
      return node.name.value;
    }
  }
  return null;
};

const invalidateAllQueries = (cache, fieldName) => {
  const queries = cache.inspectFields('Query').filter((q) => q.fieldName === fieldName);
  queries.forEach((q) => {
    cache.invalidate('Query', fieldName, q.arguments);
  });
};

const transformToDate = (parent, _args, _cache, info) => resolveISO(parent[info.fieldName]);
const transformToFloat = (parent, _args, _cache, info) => ((parent[info.fieldName] !== null)
  ? Number.parseFloat(parent[info.fieldName]) : parent[info.fieldName]);

export default function createClient(isClient, token) {

  const url = import.meta.env.VITE_GRAPHQL_ENDPOINT;
  const release = import.meta.env.RELEASE;
  const wsClient = (isClient) ? createWSClient({
    url: url.replace(/http/, 'ws'),
    lazy: true,
    keepAlive: 60 * 1000,
  }) : null;

  return {
    websocketClient: wsClient,
    client: createURQLClient({
      url,
      preferGetMethod: false,
      exchanges: [
        // devtoolsExchange,
        // refocusExchange(),
        // requestPolicyExchange({
        //   ttl: 5 * 60 * 1000,
        // }),
        cacheExchange({
          schema: schema.data,
          keys: {
            // Range has no id, just start/end-struct
            DateRange: () => null,
            DateTimeRange: () => null,
            IntegerRange: () => null,
            File: (f) => f.url,
            Dog: (d) => d.id || d.chipNumber,
          },
          resolvers: {
            Query: {
              time: (data) => resolveISO(data.time),
            },
            Order: {
              amount: transformToFloat,
              paidAt: transformToDate,
              created: transformToDate,
            },
            CombinationRegistrationItem: {
              price: transformToFloat,
              refundAmount: transformToFloat,
              expires: transformToDate,
              refundExecutedAt: transformToDate,
              invalidatedAt: transformToDate,
              created: transformToDate,
            },
            TeamRegistrationItem: {
              price: transformToFloat,
              refundAmount: transformToFloat,
              expires: transformToDate,
              refundExecutedAt: transformToDate,
              invalidatedAt: transformToDate,
              created: transformToDate,
            },
            CombinationRegistrationTicket: {
              createdAt: transformToDate,
            },
            TeamRegistrationTicket: {
              createdAt: transformToDate,
            },
            LicenseItem: {
              price: transformToFloat,
              expires: transformToDate,
            },
            EventFeesItem: {
              price: transformToFloat,
              expires: transformToDate,
              refundExecutedAt: transformToDate,
              invalidatedAt: transformToDate,
              created: transformToDate,
            },
            License: {
              costs: transformToFloat,
            },
            LicenseOption: {
              price: transformToFloat,
            },
            Dog: {
              stopped: transformToDate,
              birthday: transformToDate,
              recordBookReleasedAt: transformToDate,
            },
            DogMeasurement: {
              measuredOn: transformToDate,
            },
            EventRequestAudit: {
              createdAt: transformToDate,
              approvedAt: transformToDate,
              rejectedAt: transformToDate,
            },
            CategoryMembershipAudit: {
              createdAt: transformToDate,
              approvedAt: transformToDate,
              rejectedAt: transformToDate,
            },
            DogAudit: {
              createdAt: transformToDate,
              approvedAt: transformToDate,
              rejectedAt: transformToDate,
            },
            DogRecordBookAudit: {
              createdAt: transformToDate,
              approvedAt: transformToDate,
              rejectedAt: transformToDate,
            },
            PersonRenameAudit: {
              createdAt: transformToDate,
              approvedAt: transformToDate,
              rejectedAt: transformToDate,
            },
            Event: {
              publishedAt: transformToDate,
              registrationFees: transformToFloat,
            },
            EventDay: {
              date: transformToDate,
              measurementFinalisedAt: transformToDate,
            },
            Show: {
              start: (parent) => ((parent?.start)
                ? parse(parent?.start, 'HH:mm:ss', new Date())
                : null),
              registrationCosts: transformToFloat,
              registrationFee: transformToFloat,
              registrationFeeCustom: transformToFloat,
              publishedAt: transformToDate,
            },
            Course: {
              lockedAt: transformToDate,
              finalisedAt: transformToDate,
            },
            Grouping: {
              date: transformToDate,
              referenceDate: transformToDate,
            },
            ForeignCombinationResult: {
              date: transformToDate,
              resultDate: transformToDate,
            },
            PaymentInformation: {
              revenue: transformToFloat,
              fees: transformToFloat,
              transactionCosts: transformToFloat,
            },
            DateRange: {
              start: transformToDate,
              end: transformToDate,
            },
            DateTimeRange: {
              start: transformToDate,
              end: transformToDate,
            },
          },
          updates: {
            Mutation: {
              eventCreate: (results, args, cache) => {
                invalidateAllQueries(cache, 'adminEvents');
                invalidateAllQueries(cache, 'calendarAdminEvents');
              },
              eventDelete: (results, args, cache) => {
                invalidateAllQueries(cache, 'adminEvents');
                invalidateAllQueries(cache, 'calendarAdminEvents');
              },
              combinationTransitionGrade: (results, args, cache) => {
                invalidateAllQueries(cache, 'registrations');
                invalidateAllQueries(cache, 'user');
                invalidateAllQueries(cache, 'userShows');
                invalidateAllQueries(cache, 'currentOrder');
                invalidateAllQueries(cache, 'combination');
              },
              combinationRegisterTransitionPreferences: (results, args, cache) => {
                invalidateAllQueries(cache, 'registrations');
                invalidateAllQueries(cache, 'userShows');
              },
              combinationRegistrationReplace: (results, args, cache) => {
                invalidateAllQueries(cache, 'registrations');
                invalidateAllQueries(cache, 'eventRegistrations');
              },
              showUpdate: (results, args, cache) => {
                invalidateAllQueries(cache, 'queuesEventDayEvent');
                invalidateAllQueries(cache, 'queuesGroupGradeEvent');
                invalidateAllQueries(cache, 'queueEventDay');
                invalidateAllQueries(cache, 'queueGroupGrade');
              },
              queueSetEventQueues: (result, args, cache) => {
                if (result.queueSetEventQueues?.__typename !== 'OperationResponse') {
                  invalidateAllQueries(cache, 'queuesEventDayEvent');
                  invalidateAllQueries(cache, 'queuesGroupGradeEvent');
                  invalidateAllQueries(cache, 'queueEventDay');
                  invalidateAllQueries(cache, 'queueGroupGrade');
                }
              },
              eventClearQueues: (result, args, cache) => {
                if (result.eventClearQueues?.__typename !== 'OperationResponse') {
                  invalidateAllQueries(cache, 'queuesEventDayEvent');
                  invalidateAllQueries(cache, 'queuesGroupGradeEvent');
                  invalidateAllQueries(cache, 'queueEventDay');
                  invalidateAllQueries(cache, 'queueGroupGrade');
                }
              },
              eventDayMeasurementSettingsUpdate: (results, args, cache) => {
                invalidateAllQueries(cache, 'eventDay');
              },
              eventDayFinaliseMeasurement: (results, args, cache) => {
                invalidateAllQueries(cache, 'eventDay');
              },
              eventDayDogCancel: (results, args, cache) => {
                invalidateAllQueries(cache, 'eventDay');
              },
              basketTeamRegistrationAdd: (result, args, cache) => {
                if (result.basketTeamRegistrationAdd?.__typename === 'TeamRegistrationTicket') {
                  const registrationQuery = cache.inspectFields('Query').find(
                    (q) => q.fieldName === 'tickets' && !q.arguments.eventId,
                  );
                  if (registrationQuery) {
                    cache.updateQuery(
                      {
                        query: REGISTRATIONS_QUERY,
                        variables: registrationQuery.arguments,
                      },
                      (data) => {
                        data.tickets.push(
                          result.basketTeamRegistrationAdd,
                        );
                        return data;
                      },
                    );
                  }
                } else if (result.basketTeamRegistrationAdd?.__typename !== 'OperationResponse') {
                  const registrationQuery = cache.inspectFields('Query').find(
                    (q) => q.fieldName === 'registrations' && !q.arguments.eventId,
                  );
                  if (registrationQuery && !args.amount) {
                    cache.updateQuery(
                      {
                        query: REGISTRATIONS_QUERY,
                        variables: registrationQuery.arguments,
                      },
                      (data) => {
                        const registration = (result.basketTeamRegistrationAdd.__typename === 'TeamRegistrationItem')
                          ? result.basketTeamRegistrationAdd.registration
                          : result.basketTeamRegistrationAdd;
                        data.registrations.push(
                          registration,
                        );
                        return data;
                      },
                    );
                  }
                }
              },
              basketCombinationRegistrationAdd: (result, args, cache) => {
                if (result.basketCombinationRegistrationAdd?.__typename === 'CombinationRegistrationTicket') {
                  const registrationQuery = cache.inspectFields('Query').find(
                    (q) => q.fieldName === 'tickets' && !q.arguments.eventId,
                  );
                  if (registrationQuery) {
                    cache.updateQuery(
                      {
                        query: REGISTRATIONS_QUERY,
                        variables: registrationQuery.arguments,
                      },
                      (data) => {
                        data.tickets.push(
                          result.basketCombinationRegistrationAdd,
                        );
                        return data;
                      },
                    );
                  }
                } else if (result.basketCombinationRegistrationAdd?.__typename !== 'OperationResponse') {
                  const registrationQuery = cache.inspectFields('Query').find(
                    (q) => q.fieldName === 'registrations' && !q.arguments.eventId,
                  );
                  if (registrationQuery && !args.amount) {
                    cache.updateQuery(
                      {
                        query: REGISTRATIONS_QUERY,
                        variables: registrationQuery.arguments,
                      },
                      (data) => {
                        const registration = (result.basketCombinationRegistrationAdd.__typename === 'CombinationRegistrationItem')
                          ? result.basketCombinationRegistrationAdd.registration
                          : result.basketCombinationRegistrationAdd;
                        data.registrations.push(
                          registration,
                        );
                        return data;
                      },
                    );
                  }
                }
              },
              basketLicenseRequest: (result, args, cache) => {
                if (result.basketLicenseRequest?.__typename !== 'OperationResponse') {
                  invalidateAllQueries(cache, 'combinations');
                }
              },
              basketLicenseRemove: (result, args, cache) => {
                if (result.basketLicenseRemove?.__typename !== 'OperationResponse') {
                  // Since a license can be removed without any trace, we need to refresh it
                  invalidateAllQueries(cache, 'combinations');
                }
              },
              basketLicenseRenew: (result, args, cache) => {
                if (result.basketLicenseRequest?.__typename !== 'OperationResponse') {
                  invalidateAllQueries(cache, 'combinations');
                }
              },
              combinationRegistrationRegister: (result, args, cache) => {
                invalidateAllQueries(cache, 'group');
                invalidateAllQueries(cache, 'event');
                invalidateAllQueries(cache, 'groupRegistrations');
                invalidateAllQueries(cache, 'eventRegistrations');
              },
              teamRegistrationRegister: (result, args, cache) => {
                invalidateAllQueries(cache, 'group');
                invalidateAllQueries(cache, 'event');
                invalidateAllQueries(cache, 'show');
                invalidateAllQueries(cache, 'groupRegistrations');
                invalidateAllQueries(cache, 'eventRegistrations');
              },
              combinationRegistrationCancel: (result, args, cache) => {
                invalidateAllQueries(cache, 'group');
                invalidateAllQueries(cache, 'groupRegistrations');
              },
              teamRegistrationCancel: (result, args, cache) => {
                invalidateAllQueries(cache, 'group');
                invalidateAllQueries(cache, 'show');
                invalidateAllQueries(cache, 'groupRegistrations');
              },
              showTeamUpdate: (result, args, cache) => {
                if (result.showTeamUpdate?.__typename !== 'OperationResponse') {
                  const teamsQuery = cache.inspectFields('Query').find(
                    (q) => q.fieldName === 'teams' && q.arguments.user,
                  );
                  if (teamsQuery && result.showTeamUpdate.id && !args.showTeamId) {
                    cache.updateQuery(
                      {
                        query: TEAMS_QUERY,
                        variables: teamsQuery.arguments,
                      },
                      (data) => {
                        const team = result.showTeamUpdate;
                        if (data?.teams && args.rankingTeam) {
                          // Publish this showTeam with the ranking team, so we can
                          // deduce they are related.
                          const rt = data.teams.find((t) => t.id === args.rankingTeam);
                          rt.showTeams.push(team);
                        }
                        if (data?.teams) {
                          data?.teams.push(team);
                        }
                        return data;
                      },
                    );
                  }
                }
              },
            },
          },
        }),
        mapExchange({
          onOperation(operation) {
            if (['query', 'mutation', 'subscription'].indexOf(operation.kind) > -1) {
              const operationName = getOperationName(operation.query);
              Sentry.addBreadcrumb({
                type: 'query',
                category: operation.kind,
                message: operationName,
                data: (operationName !== 'Login') ? operation.variables : {},
                level: 'info',
              });
            }
          },
          onResult(result) {
            // This is a custom 409 response that the server is running a different version
            // of the API, so we need to get the new app.
            if (result?.data?.mustReload) {
              Sentry.close(0).then(() => {
                window.location.reload();
              });
            }
          },
        }),
        subscriptionExchange({
          forwardSubscription: (operation) => ({
            subscribe: (sink) => ({
              unsubscribe: wsClient.subscribe({
                // Rewrite urqls SubscriptionPayload to graphql-ws's payload
                variables: operation.variables,
                query: operation.query,
                // XXX: Very ugly, but it works. We would rather have
                //      urql expose the operationName, but it flattens its Operation in this object
                operationName: operation.operationName,
              }, sink),
            }),
          }),
        }),
        fetchExchange,
      ],
      fetchOptions: () => {
        const CSRFToken = Cookies.get('csrftoken');

        if (import.meta.env.PROD) {
          try {
            const CSRFTokenChecked = localStorage.getItem('urqlCSRFCheck') || false;
            // We reload once, which should give us a token
            if (!CSRFToken && !CSRFTokenChecked && typeof window !== 'undefined') {
              localStorage.setItem('urqlCSRFCheck', 'true');
              window.location.reload();
            } else if (CSRFToken && CSRFTokenChecked) {
              localStorage.removeItem('urqlCSRFCheck');
            }
          } catch {
            // If LocalStorage gives us SecurityError, so be it.
          }
        }

        const currentLang = (typeof document === 'undefined') ? 'nl' : document.documentElement.lang;
        return {
          headers: {
            'X-CSRFToken': CSRFToken || undefined,
            'X-Release': release,
            'Accept-Language': currentLang,
            Referer: import.meta.env.VITE_HOST,
            Authorization: (token?.accessToken) ? `Bearer ${token.accessToken}` : undefined,
          },
        };
      },

    }),
  };
}
